<template>
  <v-hover v-slot:default="{ hover }" open-delay="200">
    <v-card :elevation="hover ? 16 : 2">
      <div class="text-center pt-3">
        <v-avatar color>
          <v-icon size="36" color="primary">mdi-account-group</v-icon>
        </v-avatar>

        <v-card-text justify="center" class="py-5">
          <p class="font-weight-medium text-center title">
            {{$t('cad')}} {{ $t(data.title) }}
          </p>
        </v-card-text>
      </div>
      <v-card-actions>
        <v-col md="12" sm="12">
          <div class="text-center">
            <v-btn color="primary" @click.prevent="$emit('openDialog', data.title)" class="ma-1" dense>
              <v-icon color="white" left>mdi-plus</v-icon>
              {{ $t(data.titleButton) }}
              
            </v-btn>
          </div>
        </v-col>
      </v-card-actions>
    </v-card>
  </v-hover>
</template>

<script>
export default {
  name: 'RegisterCard',
  props: {
    data: Object
  }
}
</script>