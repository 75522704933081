export default class User {
  constructor (id, email, name, newPhoto, office, password, photo, type, telephone, role, status) {
    this.id = id
    this.email = email
    this.name = name
    this.newPhoto = newPhoto
    this.office = office
    this.password = password
    this.photo = photo
    this.type = type
    this.telephone = telephone
    this.role = role
    this.status = status
  }
}