import gql from 'graphql-tag'

export const GET_CANDIDATES_ENTITY_QUERY = gql`
query GetCandidatesEntity($filterCandidate: FilterCandidate) {
  candidates: getCandidatesEntity(filterCandidate: $filterCandidate) {
    candidates {
      id
    }
    totalDocs
    totalPages
  }
}
`

export const STATISTICS_QUERY = gql`
  query statisticsEntity {
    statistics: statisticsEntity {
      totalCandidates
      totalViewsVacancy
    }
  }
`