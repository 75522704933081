export default class Entity {
  constructor (
    id, address, areasInterest, availability, basicSalary, city, dateBirth, email, formation, genre, idealSalary, languages, lastName, media, name, 
    nationality, newPhoto, otherSkills, photo, presentation, profession, professionalExperience, references, status, telephone, telephone2,province
  ) {
    this.id = id
    this.address = address
    this.areasInterest = areasInterest
    this.availability = availability
    this.basicSalary = basicSalary
    this.city = city
    this.dateBirth = dateBirth
    this.email = email
    this.formation = formation
    this.genre = genre
    this.idealSalary = idealSalary
    this.languages = languages
    this.lastName = lastName
    this.media = media
    this.name = name
    this.nationality = nationality
    this.newPhoto = newPhoto
    this.otherSkills = otherSkills
    this.photo = photo
    this.presentation = presentation
    this.profession = profession
    this.professionalExperience = professionalExperience
    this.references = references
    this.status = status
    this.telephone = telephone
    this.telephone2 = telephone2
    this.province = province
  }
}