<template>
  <v-card flat>
    <v-card-text>
      <v-row class="text-right">
        <v-col cols="12">
          <v-row class="">
            <v-col cols="2" md="2">
              <v-icon :color="item.iconColor" class="text-left ">{{
                item.icon
              }}</v-icon>
            </v-col>

            <v-col cols="10">
              <p  class="black--text">{{ $t(item.title) }}</p>
            </v-col>
          </v-row>
        </v-col>

        <v-col cols="12" style="margin-top: -10%">
          <p class="text-right ">{{ item.value }}</p>
        </v-col>
      </v-row>

      <v-progress-linear
        :color="item.color"
        height="10"
        value="100"
        striped
      ></v-progress-linear>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "ActivityCard",
  props: {
    item: Object,
  },
};
</script>
